import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import logo from './telerad-logo.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-left">
          <img src={logo} alt="TeleRad Logo" className="footer-logo-large" />
          <h2 className="footer-title-large">
            <span>YOUR</span>
            <span>REMOTE</span>
            <span>RADIOLOGY</span>
            <span>PARTNER</span>
          </h2>
          <div className="footer-contact">
            <p><FontAwesomeIcon icon={faPhone} className="footer-icon" /> +970 000 000</p>
            <p><FontAwesomeIcon icon={faEnvelope} className="footer-icon" /> info@telerad.com</p>
            <p><FontAwesomeIcon icon={faMapMarkerAlt} className="footer-icon" /> 123 Health Street, Ramallah, Palestine</p>
          </div>
          <div className="footer-social">
            <FontAwesomeIcon icon={faFacebookF} />
            <FontAwesomeIcon icon={faXTwitter} />
            <FontAwesomeIcon icon={faInstagram} />
            <FontAwesomeIcon icon={faLinkedinIn} />
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-menu">
            <ul className="footer-list">
              <li>Home</li>
              <li>About us</li>
              <li>Our vision</li>
              <li>Our mission</li>
              <li>Our team</li>
              <li>Contact</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© {currentYear} TeleRad. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
