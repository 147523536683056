import React, { useState, useEffect, forwardRef } from 'react';
import './Consult.css';

const Consult = forwardRef((props, ref) => {
  const [step, setStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileLink, setFileLink] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [medicalCentreCustomerType, setMedicalCentreCustomerType] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [timerActive, setTimerActive] = useState(false);

  const [confirmationCountdown, setConfirmationCountdown] = useState(10); // for step 10

  useEffect(() => {
    if (selectedOption === 'medical-centre' && step === 11 && medicalCentreCustomerType === 'new') {
      setTimerActive(true);
      setConfirmationCountdown(10); // Reset countdown
    } else {
      setTimerActive(false);
    }
  }, [selectedOption, step, medicalCentreCustomerType]);

  useEffect(() => {
    if (timerActive) {
      const interval = setInterval(() => {
        setConfirmationCountdown(prev => {
          if (prev <= 1) {
            clearInterval(interval);
            setMedicalCentreCustomerType('existing');
            setStep(8); // Redirect to Reference Code step
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timerActive]);


  const nextStep = () => {
    if (step === 1 && selectedOption === 'individual') {
      setStep(2);
    } else if (step === 1 && selectedOption === 'medical-centre') {
      setStep(7); // Move to step 7 for Medical Centre
    } else if (step === 2 && selectedOption === 'individual') {
      setStep(3);
    } else if (step === 3 && selectedOption === 'individual' && selectedService !== null) {
      setStep(4);
    } else if (step === 4 && selectedOption === 'individual' && (file || fileLink)) {
      setStep(5);
    } else if (step === 5 && selectedOption === 'individual') {
      setStep(6);
    } else if (step === 6 && selectedOption === 'individual') {
      setStep(7);
    } else if (step === 7 && selectedOption === 'medical-centre' && medicalCentreCustomerType !== null) {
      setStep(8); // Proceed to the next step after selecting customer type for Medical Centre
    } else if (step === 8 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new') {
      setStep(9); // Move to step 9 for new Medical Centre
    } else if (step === 8 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing') {
      setStep(9); // Move to step 9 for existing Medical Centre
    } else if (step === 9 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new') {
      setStep(10); // Move to step 10 for new Medical Centre
    } else if (step === 9 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing' && selectedService !== null) {
      setStep(10); // Only move to step 10 if a service is selected for existing Medical Centre
    } else if (step === 10 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new') {
      setStep(11); // Move to step 11 for new Medical Centre
    }
       else if (step === 10 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing') {
      setStep(11); // Move to step 11 for existing Medical Centre
    } 
    else if (step === 11 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new') {
        setStep(12);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      if (selectedOption === 'medical-centre') {
        if (step > 7) {
          setStep(step - 1);
        } else if (step === 7) {
          setStep(1); // Go back to the initial step when in Medical Centre and at step 7
          setSelectedOption(null);
        }
      } else {
        setStep(step - 1);
      }
    }
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    // Reset steps and selected values if option changes
    setStep(1);
    setSelectedService(null);
    setFile(null);
    setFileName('');
    setFileLink('');
    setUploadProgress(0);
    setMedicalCentreCustomerType(null);
    setConfirmationCountdown(10);
  };

  const selectService = (service) => {
    setSelectedService(service);
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setFileName(uploadedFile.name);
      // Simulate upload progress
      let progress = 0;
      const interval = setInterval(() => {
        if (progress < 100) {
          progress += 10;
          setUploadProgress(progress);
        } else {
          clearInterval(interval);
        }
      }, 100);
    }
  };

  const handleLinkChange = (event) => {
    setFileLink(event.target.value);
  };

  const handlePlanSelection = (plan) => {
    setSelectedPlan(plan);
  };
  

  const isNextButtonDisabled = () => {
    switch (step) {
      case 1:
        return selectedOption === null;
      case 2:
        return selectedOption === 'individual' ? false : medicalCentreCustomerType === null;
      case 3:
        return selectedOption === 'individual' && selectedService === null;
      case 4:
        return selectedOption === 'individual' && !(file || fileLink);
      case 5:
        return selectedOption === 'individual';
      case 8:
        return selectedOption === 'medical-centre' && medicalCentreCustomerType === null;
      case 9:
        if (selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new') {
          return selectedPlan === null;
        }
        if (selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing') {
          return selectedService === null;
        }
        return false;
      default:
        return false;
    }
  };

  return (
    <div ref={ref} className="consult-container">
      <div className="consult-left">
        <h1 className="consult-title">Reach out <br /> our experts</h1>
        <p className="consult-subtitle">Complete the following easy steps by filling out your information so our team can assist you.</p>
        <div className="consult-step-counter">
          {step === 1 && (
            <div className="step-box">
              <p className="step-brief">SELECT USER TYPE</p>
              <span className="step-number">1</span>
            </div>
          )}
          {step === 2 && selectedOption === 'individual' && (
            <div className="step-box">
              <p className="step-brief">PLEASE ENTER YOUR INFORMATION</p>
              <span className="step-number">2</span>
            </div>
          )}
          {step === 3 && selectedOption === 'individual' && (
            <div className="step-box">
              <p className="step-brief">SELECT RADIOLOGY SERVICE</p>
              <span className="step-number">3</span>
            </div>
          )}
          {step === 4 && selectedOption === 'individual' && (
            <div className="step-box">
              <p className="step-brief">UPLOAD FILE OR PROVIDE LINK</p>
              <span className="step-number">4</span>
            </div>
          )}
          {step === 5 && selectedOption === 'individual' && (
            <div className="step-box">
              <p className="step-brief">PLEASE COMPLETE PAYMENT PROCESS</p>
              <span className="step-number">5</span>
            </div>
          )}
          {step === 6 && selectedOption === 'individual' && (
            <div className="step-box">
              <p className="step-brief">CONFIRM PAYMENT DETAILS</p>
              <span className="step-number">6</span>
            </div>
          )}
          {step === 7 && selectedOption === 'medical-centre' && (
            <div className="step-box">
              <p className="step-brief">SELECT CUSTOMER TYPE</p>
              <span className="step-number">2</span>
            </div>
          )}
          {step === 8 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new' &&  (
            <div className="step-box">
              <p className="step-brief">PLEASE ENTER MEDICAL CENTRE INFORMATION</p>
              <span className="step-number">3</span>
            </div>
          )}
          {step === 8 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing' &&  (
            <div className="step-box">
              <p className="step-brief">PLEASE ENTER YOUR REFERENCE CODE</p>
              <span className="step-number">3</span>
            </div>
          )}
          {step === 9 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new' && (
            <div className="step-box">
              <p className="step-brief">SELECT A PLAN</p>
              <span className="step-number">4</span>
            </div>
          )}
             {step === 9 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing' && (
            <div className="step-box">
              <p className="step-brief">SELECT RADIOLOGY SERIVCE</p>
              <span className="step-number">4</span>
            </div>
          )}
          {step === 10 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'new' && (
            <div className="step-box">
              <p className="step-brief">COMPLETE PAYMENT</p>
              <span className="step-number">5</span>
            </div>
          )}
          {step === 10 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing' && (
            <div className="step-box">
              <p className="step-brief">UPLOAD FILE OR PROVIDE LINK</p>
              <span className="step-number">6</span>
            </div>
          )}
          {step === 11 && selectedOption === 'medical-centre' && medicalCentreCustomerType === 'existing' && (
            <div className="step-box">
              <p className="step-brief">CONFIRMATION</p>
              <span className="step-number">7</span>
            </div>
          )}
        </div>
      </div>
      <div className="consult-right">
        {step === 1 && (
          <div className="consult-step consult-step1">
            <button
              className={`consult-option ${selectedOption === 'individual' ? 'selected' : ''}`}
              onClick={() => selectOption('individual')}
            >
              <img src="https://icons.veryicon.com/png/o/miscellaneous/linear-icon-6/icon_-individual.png" alt="Individual" />
              <p>Individual</p>
            </button>
            <button
              className={`consult-option ${selectedOption === 'medical-centre' ? 'selected' : ''}`}
              onClick={() => selectOption('medical-centre')}
            >
              <img src="https://cdn-icons-png.flaticon.com/512/5572/5572281.png" alt="Medical Centre" />
              <p>Medical Centre</p>
            </button>
          </div>
        )}

        {selectedOption === 'individual' && step === 2 && (
          <div className="consult-step consult-step2">
            <input type="text" placeholder="FULL NAME" />
            <input type="date" placeholder="DATE OF BIRTH" />
            <input type="text" placeholder="MOBILE NUMBER" />
            <input type="email" placeholder="EMAIL ADDRESS" />
            <select>
              <option>GENDER</option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>
        )}

        {selectedOption === 'individual' && step === 3 && (
          <div className="consult-step consult-step3">
            <p>Select the type of radiology service you want:</p>
            <button onClick={() => selectService('CT scan single part')}>CT scan single part</button>
    <button onClick={() => selectService('CT scan whole body')}>CT scan whole bodyI</button>
    <button onClick={() => selectService('MRI single part')}>MRI single part</button>
    <button onClick={() => selectService('X-ray')}>X-ray</button>
    <button onClick={() => selectService('Mammogram')}>Mammogram</button>
    <button onClick={() => selectService('PET scan')}>PET scan</button>
          </div>
        )}

        {selectedOption === 'individual' && step === 4 && (
          <div className="consult-step consult-step4">
            <div className="upload-section">
              <h3>Upload your file or copy and paste your link</h3>
              <i className="upload-icon">☁️</i>
              <input
                type="file"
                onChange={handleFileUpload}
                className="file-input"
              />
              <input
                type="text"
                placeholder="Or provide a link"
                value={fileLink}
                onChange={handleLinkChange}
                className="link-input"
              />
              {file && (
                <div className="file-info">
                  <p>Uploaded file: {fileName}</p>
                  <progress value={uploadProgress} max="100"></progress>
                </div>
              )}
              {fileLink && (
                <div className="file-info">
                  <p>Provided link: <a href={fileLink} target="_blank" rel="noopener noreferrer">{fileLink}</a></p>
                </div>
              )}
            </div>
          </div>
        )}

        {selectedOption === 'individual' && step === 5 && (
          <div className="consult-step consult-step5">
            <h2 className="consult-plan-title">Our dear customer,</h2>
            <p>You’ve chosen <span className="highlight">Individual Plan</span></p>
            <p>This plan includes reviewing your uploaded files in the previous step.</p>
            <div className="consult-summary">
              <p>Number of documents shared: <span>2</span></p>
              <p>Total Amount including tax: <span>$29.00</span></p>
            </div>
            <button className="proceed-button" onClick={nextStep}>PROCEED</button>
          </div>
        )}

        {step === 6 && selectedOption === 'individual' && (
          <div className="consult-step consult-step6">
            <div className="payment-container">
              <div className="payment-option">
                <input
                  type="radio"
                  id="debit-credit"
                  name="payment-method"
                  checked
                />
                <label htmlFor="debit-credit">
                  Debit/Credit Card
                  <div className="card-icons">
                    <img src="https://cdn-icons-png.flaticon.com/512/196/196578.png" alt="Visa" />
                  </div>
                </label>
              </div>

              <div className="card-section">
                <label htmlFor="card-number">Card Number</label>
                <input type="text" id="card-number" placeholder="1234 5678 9012 3456" />

                <div className="separator"></div>

                <div className="card-details">
                  <div>
                    <label htmlFor="expiry-date">Expiry Date</label>
                    <input type="text" id="expiry-date" placeholder="MM/YY" />
                  </div>
                  <div>
                    <label htmlFor="cvc">CVC</label>
                    <input type="text" id="cvc" placeholder="123" />
                  </div>
                </div>
              </div>

              <button className="submit-button">Finish and Pay</button>
            </div>
          </div>
        )}

        {selectedOption === 'medical-centre' && step === 7 && (
          <div className="consult-step consult-step7">
            <button
              className={`consult-option ${medicalCentreCustomerType === 'new' ? 'selected' : ''}`}
              onClick={() => setMedicalCentreCustomerType('new')}
            >
              New Customer
            </button>
            <button
              className={`consult-option ${medicalCentreCustomerType === 'existing' ? 'selected' : ''}`}
              onClick={() => setMedicalCentreCustomerType('existing')}
            >
              Existing Customer
            </button>
          </div>
        )}

        {selectedOption === 'medical-centre' && step === 8 && medicalCentreCustomerType === 'new' && (
          <div className="consult-step consult-step8">
            <input type="text" placeholder="Medical Centre Name" />
            <input type="text" placeholder="Contact Person" />
            <input type="text" placeholder="Contact Number" />
            <input type="email" placeholder="Email Address" />
          </div>
        )}

{selectedOption === 'medical-centre' && step === 8 && medicalCentreCustomerType === 'existing' && (
          <div className="consult-step consult-step8">
            <p>Enter Your Reference Code We Sent You Via Email:</p>
            <input type="text" placeholder="Reference Code" />
          </div>
        )}

{selectedOption === 'medical-centre' && step === 9 && medicalCentreCustomerType === 'new' && (
  <div className="consult-step consult-step9">
    <button
      className={`consult-option ${selectedPlan === 'Plan A' ? 'selected' : ''}`}
      onClick={() => handlePlanSelection('Plan A')}
    >
      Plan A
    </button>
    <button
      className={`consult-option ${selectedPlan === 'Plan B' ? 'selected' : ''}`}
      onClick={() => handlePlanSelection('Plan B')}
    >
      Plan B
    </button>
    <button
      className={`consult-option ${selectedPlan === 'Plan C' ? 'selected' : ''}`}
      onClick={() => handlePlanSelection('Plan C')}
    >
      Plan C
    </button>
  </div>
)}


{selectedOption === 'medical-centre' && step === 9 && medicalCentreCustomerType === 'existing' && (
  <div className="consult-step consult-step9-existing">
    <p>Select Radiology Service</p>
    <button onClick={() => selectService('CT scan single part')}>CT scan single part</button>
    <button onClick={() => selectService('CT scan whole body')}>CT scan whole bodyI</button>
    <button onClick={() => selectService('MRI single part')}>MRI single part</button>
    <button onClick={() => selectService('X-ray')}>X-ray</button>
    <button onClick={() => selectService('Mammogram')}>Mammogram</button>
    <button onClick={() => selectService('PET scan')}>PET scan</button>
    {/* Add more services as needed */}
  </div>
)}


{selectedOption === 'medical-centre' && step === 10 && medicalCentreCustomerType === 'new' && (
  <div className="consult-step consult-step10-payment">
    <div className="payment-container">
      <div className="payment-option">
        <input
          type="radio"
          id="debit-credit"
          name="payment-method"
          checked
        />
        <label htmlFor="debit-credit">
          Debit/Credit Card
          <div className="card-icons">

            <img src="https://cdn-icons-png.flaticon.com/512/196/196578.png" alt="VISA" />
          </div>
        </label>
      </div>

      <div className="card-section">
        <label htmlFor="card-number">Card Number</label>
        <input type="text" id="card-number" placeholder="1234 5678 9012 3456" />

        <div className="separator"></div>

        <div className="card-details">
          <div>
            <label htmlFor="expiry-date">Expiry Date</label>
            <input type="text" id="expiry-date" placeholder="MM/YY" />
          </div>
          <div>
            <label htmlFor="cvc">CVC</label>
            <input type="text" id="cvc" placeholder="123" />
          </div>
        </div>

        <button className="submit-button" onClick={nextStep}>Finish and Pay</button>
      </div>
    </div>
  </div>
)}


{selectedOption === 'medical-centre' && step === 11 && medicalCentreCustomerType === 'new' && (
        <div className="consult-step consult-step11">
          <p>Confirmation</p>
          <p>Your request has been submitted successfully. Thank you!</p>
          <p>Redirecting to Reference Code in {confirmationCountdown} seconds...</p>
        </div>
      )}



{selectedOption === 'medical-centre' && step === 10 && medicalCentreCustomerType === 'existing' && (
  <div className="consult-step consult-step10">
    <p>Upload File or Provide Link</p>
    <input type="file" onChange={handleFileUpload} />
    <input type="text" placeholder="File Link" value={fileLink} onChange={handleLinkChange} />
    {file && <p className="file-info">File Selected: {fileName}</p>}
    {fileLink && <p className="file-info">File Link: {fileLink}</p>}
  </div>
)}





        <div className="consult-navigation">
          <button className="nav-button back-button" onClick={prevStep}>Back</button>
          <button
            className="nav-button next-button"
            onClick={nextStep}
            disabled={isNextButtonDisabled()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
});

export default Consult;
