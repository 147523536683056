import React from 'react';
import './How.css';

const How = () => {
  return (
    <div className="how-wrapper">
      <div className="how-container">
        <div className="how-column">
          <div className="how-image">
            <img src="https://drfischerspinesurgeon.com/wp-content/uploads/2021/03/Can-Arthritis-Occur-in-Your-Back.jpg" alt="Placeholder" />
            <div className="how-number">1</div>
          </div>
          <h3 className="how-title">Share Images</h3>
          <p className="how-paragraph">Securely share medical images to our platform</p>
        </div>
        <div className="how-column middle">
          <div className="how-image">
            <img src="https://img.freepik.com/free-photo/medium-shot-doctor-nurse-looking-radiography_23-2149310018.jpg" alt="Placeholder" />
            <div className="how-number">2</div>
          </div>
          <h3 className="how-title">Expert Analysis</h3>
          <p className="how-paragraph">Our radiologists review and interpret the images.</p>
        </div>
        <div className="how-column">
          <div className="how-image">
            <img src="https://previews.123rf.com/images/armmypicca/armmypicca2311/armmypicca231105757/229460671-%C3%A9quipe-multiraciale-de-m%C3%A9decins-discutant-d-un-patient-debout-regroup%C3%A9-dans-le-hall-regardant-une.jpg" alt="Placeholder" />
            <div className="how-number">3</div>
          </div>
          <h3 className="how-title">Receive Report</h3>
          <p className="how-paragraph">Receive a detailed report and consultation, if needed, within the agreed timeframe</p>
        </div>
      </div>
      <div className="how-button-container">
        <button className="how-button">Consult Us Today</button>
      </div>
    </div>
  );
};

export default How;
