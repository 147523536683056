import React, { useState } from 'react';
import './ServicesPage.css';

const ServicesPage = () => {
    const [activeService, setActiveService] = useState('Teleradiology Reporting');

    const servicesData = {
        'Teleradiology Reporting': {
            title: 'Teleradiology Reporting',
            brief: 'Our expert radiologists provide detailed and accurate interpretations of medical images.',
            image: 'https://img.freepik.com/free-photo/medium-shot-smiley-nurse-ct-scan-room_23-2149341550.jpg', // replace with the actual image URL or import statement
            additionalInfo: 'We ensure quick turnaround times to support timely clinical decision-making.'
        },
        'Consultation Services': {
            title: 'Consultation Services',
            brief: 'Our consultants offer expert advice on various medical cases and second opinions.',
            image: 'https://img.freepik.com/free-photo/doctor-discussing-x-ray-with-patient_1170-2102.jpg',
            additionalInfo: 'Our services ensure you get the right guidance and expertise for your medical needs.'
        },
        'Second Opinions': {
            title: 'Second Opinions',
            brief: 'We provide thorough reviews of diagnoses and treatment plans for reassurance.',
            image: 'https://img.freepik.com/free-photo/general-practitioner-presenting-x-ray-neck-pediatrician-specialist-with-protection-mask-providing-health-care-services-radiographic-treatment-examination-coronavirus-hospital-cabinet_482257-14506.jpg',
            additionalInfo: 'Our second opinion service is designed to give you peace of mind and clarity.'
        }
    };

    const currentService = servicesData[activeService];

    return (
        <section className="services-page">
            {/* Left Section - Rotated Title */}
            <div className="services-title2">
                <h1>SERVICES</h1>
            </div>

            <div className="services-content">
                {/* Title Section with Green Background */}
                <div className="services-header-section">
                    <h1 className="services-header">{currentService.title}</h1>
                    <p className="services-description">
                        {currentService.brief}
                    </p>
                </div>

                {/* Service Navigation Tabs and Content */}
                <div className="services-body-section">
                    <div className="services-tabs">
                        <ul>
                            {Object.keys(servicesData).map(service => (
                                <li 
                                    key={service} 
                                    className={service === activeService ? 'active' : ''} 
                                    onClick={() => setActiveService(service)}
                                >
                                    {service}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Image and Additional Info */}
                    <div className="services-image-placeholder">
                        <img src={currentService.image} alt={currentService.title} style={{ width: '100%', height: '100%' }} />
                    </div>
                    <p className="services-additional-info">{currentService.additionalInfo}</p>
                </div>
            </div>
        </section>
    );
};

export default ServicesPage;
