import React from 'react';
import { Link } from 'react-router-dom';
import './Services.css';
import teleradIcon from './telerad-icon.svg';

const Services = ({ onHowItWorksClick }) => {
  return (
    <div className="services-container">
      <div className="services-title">
        <h1>OUR<br /> SERVICES</h1>
        <Link to="/services" className="explore-more-button">Explore More</Link>
        <button
          onClick={onHowItWorksClick}
          className="get-to-know-us2"
        >
          <span className="button-text">HOW IT WORKS</span>
          <span className="plus-sign2">+</span>
        </button>
      </div>
      <div className="services-image-container">
        <div className="services-image">
          <img 
            src="https://elifeclinics.com/wp-content/uploads/2023/03/doctor-looking-ct-scan.jpg" 
            alt="Placeholder" 
            className="background-image"
          />
        </div>
        <div className="services-list">
          <div className="service-item">
            <img src={teleradIcon} alt="Service Icon" className="service-icon" />
            <div className="service-description">
              <h1>Teleradiology Reporting</h1>
              <p>Accurate and timely interpretation of medical images</p>
            </div>
          </div>
          <div className="service-item">
            <img src={teleradIcon} alt="Service Icon" className="service-icon" />
            <div className="service-description">
              <h1>Consultation Services</h1>
              <p>Expert opinions on complex cases</p>
            </div>
          </div>
          <div className="service-item">
            <img src={teleradIcon} alt="Service Icon" className="service-icon" />
            <div className="service-description">
              <h1>Second Opinions</h1>
              <p>Reliable second opinions to support diagnostic decisions</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
