import React from 'react';
import './Team.css';

const teamMembers = [
  {
    name: 'Dr. Ahmad Odah',
    title: 'Diagnostic Radiologist',
    imageUrl: 'https://png.pngtree.com/png-vector/20230928/ourmid/pngtree-young-afro-professional-doctor-png-image_10148632.png',
  },
  {
    name: 'Ali Hamdallah',
    title: 'Executive Partner',
    imageUrl: 'https://png.pngtree.com/png-clipart/20230918/ourmid/pngtree-photo-men-doctor-physician-chest-smiling-png-image_10132895.png',
  },
  {
    name: 'Emily Johnson',
    title: 'UI/UX Designer',
    imageUrl: 'https://static.vecteezy.com/system/resources/thumbnails/045/943/822/small/confident-businesswoman-with-arms-crossed-smiling-portrait-png.png',
  },
  // Add more team members as needed
];

const Team = () => {
  return (
    <div className="team-container text-center my-16">
      <h2 className="team text-4xl font-bold mb-12">Meet Our Team</h2>
      <div className="team flex flex-wrap justify-center gap-8">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member text-center">
            <div className="w-40 h-40 rounded-full overflow-hidden mx-auto mb-4">
              <img
                src={member.imageUrl}
                alt={`${member.name}'s profile`}
                className="w-full h-full object-cover"
              />
            </div>
            <h3 className="text-xl font-bold">{member.name}</h3>
            <p className="text-gray-600">{member.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
